<template>
  <div class="main-box">
    <el-form class="form-inline" :inline="true">
      <el-form-item label="姓名">
        <el-input v-model="search.keywords" placeholder="请输入姓名" size="small" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="small" @click="handleSearch()">搜索</el-button>
      </el-form-item>
    </el-form>
    <!--div class="mg-bt-10">
      <el-button type="danger" size="small" icon="el-icon-delete" @click="delGroup()">删除</el-button>
    </div-->
    <el-table
            :data="tableData"
            ref="rfTable"
            border
            style="width: 100%"
            size="mini"
            @selection-change="handleSelectionChange">
      <!--el-table-column
              type="selection"
              align="center"
              width="55">
      </el-table-column-->
      <el-table-column
              prop="id"
              label="ID"
              align="center"
              width="100">
      </el-table-column>
      <el-table-column
              prop="real_name"
              label="姓名">
      </el-table-column>
      <el-table-column
              prop="name"
              label="昵称"
              align="center"
              width="180">
      </el-table-column>
      <el-table-column
              prop="email"
              label="邮箱"
              align="center"
              width="160">
      </el-table-column>
      <el-table-column
              prop="mobile"
              label="手机号"
              align="center"
              width="160">
      </el-table-column>
      <el-table-column
              prop="school"
              label="所属学校"
              align="center"
              width="120">
      </el-table-column>
      <el-table-column
              prop="faculty"
              label="院系"
              align="center"
              width="140">
      </el-table-column>
      <el-table-column
              prop="create_time"
              label="添加时间"
              align="center"
              width="140">
      </el-table-column>
    </el-table>
    <el-pagination
            background
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
            layout="total, prev, pager, next, jumper"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="search.limit"
            :total="search.total">
    </el-pagination>
  </div>
</template>
<script>

  export default {
    components: {
    },
    data() {
      return {
        info: false,
        source_domain: this.config.SOURCE_DOMAIN,
        search: {
          current_page: 1,
          total: 0,
          limit: 15,
          keywords: ''
        },
        tableData: []
      };
    },
    created() {
      this.getList();
    },
    mounted() {
      //
    },
    computed: {
    },
    methods: {
      getList() {
        var that = this
        that.showLoading()
        var param = {
          page: that.search.current_page,
          page_size: that.search.limit,
          keywords: that.search.keywords
        }
        this.$api.merchant.teacherIndex(param, function (res) {
          that.hideLoading()
          if(res.errcode == 0) {
            that.tableData = res.data.data
            that.search.current_page = res.data.current_page
            that.search.total = res.data.total
          } else {
            that.fail(res.errmsg)
          }
        })
      },
      handleSearch() {
        this.search.current_page = 1
        this.getList()
      },
      handleSelectionChange(rows) {
      },
      handleCurrentChange(val) {
        this.search.current_page = val
        this.getList()
      },
      handleSizeChange(val) {
        this.search.current_page = 1
        this.search.limit = val
        this.getList()
      }
    }
  };
</script>
<style scoped>
</style>
